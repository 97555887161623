


















import ReferenceDTO from '@/models/ReferenceDTO';
import RemoteServices from '@/services/RemoteServices';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Sources extends Vue {
    currentTab = 4;
    references: ReferenceDTO[] = [];

    async created(): Promise<void> {
        RemoteServices.getCenoteReferences(this.$route.params.key)
            .then((references) => (this.references = references))
            .catch((error) => this.$store.dispatch('error', error));
    }
}
