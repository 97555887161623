




















































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CVariables extends Vue {
    currentTab = 1;
}
