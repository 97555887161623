











import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
    created(): void {
        setTimeout(async () => {
            await this.$router.push({ name: 'Home' });
        }, 5000);
    }
}
