
















import CalculatedVariables from '@/components/cenote/CalculatedVariables.vue';
import GeneralTab from '@/components/cenote/GeneralTab.vue';
import SocialTab from '@/components/cenote/SocialTab.vue';
import Sources from '@/components/cenote/Sources.vue';
import ThematicData from '@/components/cenote/ThematicData.vue';
import CenoteDTO from '@/models/CenoteDTO';
import { Component, Vue } from 'vue-property-decorator';

const CenoteProps = Vue.extend({
    props: {
        cenote: CenoteDTO,
    },
});

@Component({
    components: {
        GeneralTab,
        CalculatedVariables,
        SocialTab,
        ThematicData,
        Sources,
    },
})
export default class CenoteDetails extends CenoteProps {
    currentTab = null;
    tabs = [
        { tab: 'General', component: 'GeneralTab' },
        { tab: 'Calculated Variables', component: 'CalculatedVariables' },
        { tab: 'Social', component: 'SocialTab' },
        { tab: 'Thematic Data', component: 'ThematicData' },
        { tab: 'Sources', component: 'Sources' },
    ];
}
