







































import VariableWithValuesDTO from '@/models/VariableWithValuesDTO';
import RemoteServices from '@/services/RemoteServices';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class ThematicData extends Vue {
    variables: { [theme: string]: VariableWithValuesDTO[] } = {};
    currentTab = null;
    tabs = [
        'LOCATION',
        'GEOREFERENCE',
        'CULTURAL',
        'GEOMORPHOLOGY',
        'BIODIVERSITY',
        'DISTURBANCE',
        'TOURISM',
        'DIVING',
        'ORGANIZATION',
        'REGULATION',
        'WATER',
    ];

    async created(): Promise<void> {
        this.tabs.forEach((theme) => {
            RemoteServices.getData(this.$route.params.key, theme)
                .then((vars) => Vue.set(this.variables, theme, vars))
                .catch((error) => this.$store.dispatch('error', error));
        });
    }
}
