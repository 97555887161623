










import SignupCard from '@/components/auth/SignupCard.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: { SignupCard },
})
export default class Signup extends Vue {
    async signup(name: string, email: string, password: string): Promise<void> {
        await this.$store.dispatch('loading');
        try {
            await this.$store.dispatch('signup', { name, email, password });
            await this.$router.push({ name: 'Home' }).catch(() => {
                // Ignore errors
                return;
            });
        } catch (error) {
            await this.$store.dispatch('error', error);
        }
        await this.$store.dispatch('clearLoading');
    }
}
