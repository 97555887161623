
















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
    // Name of routes that should not have footer
    footer_blacklist = ['Map'];

    logos = [
        {
            src: require('@/assets/logos/unam_logo.png'),
            alt: 'logo unam',
            white_only: false,
            height: '30',
        },
        {
            src: require('@/assets/logos/ciencias_logo.png'),
            alt: 'logo ciencias',
            white_only: false,
            height: '30',
        },
        {
            src: require('@/assets/logos/logo_centro_geo.png'),
            alt: 'logo centro geo',
            white_only: false,
            height: '25',
        },
        {
            src: require('@/assets/logos/sds_logo.png'),
            alt: 'logo sds',
            white_only: true,
            height: '30',
        },
        {
            src: require('@/assets/logos/logo_uady.png'),
            alt: 'logo uady',
            white_only: true,
            height: '25',
        },
        {
            src: require('@/assets/logos/cenoteando_logo.png'),
            alt: 'logo cenoteando',
            white_only: true,
            height: '25',
        },
    ];
}
