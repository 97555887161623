










import LoginCard from '@/components/auth/LoginCard.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: { LoginCard },
})
export default class Login extends Vue {
    async login(email: string, password: string): Promise<void> {
        await this.$store.dispatch('loading');
        try {
            await this.$store.dispatch('login', { email, password });
            await this.$router.push({ name: 'Home' }).catch(() => {
                // Ignore errors
                return;
            });
        } catch (error) {
            await this.$store.dispatch('error', error);
        }
        await this.$store.dispatch('clearLoading');
    }
}
