






import { Component, Vue, Watch } from 'vue-property-decorator';
@Component
export default class Loading extends Vue {
    loading: boolean = this.$store.getters.getLoading;
    created(): void {
        this.loading = this.$store.getters.getLoading;
        this.$store.watch(
            (state, getters) => getters.getLoading,
            () => {
                this.loading = this.$store.getters.getLoading;
            },
        );
    }
    @Watch('loading')
    closeError(): void {
        if (!this.loading) {
            this.$store.dispatch('clearLoading');
        }
    }
}
