



































import CenoteDTO from '@/models/CenoteDTO';
import { Component, Vue } from 'vue-property-decorator';
import { LCircleMarker, LPopup } from 'vue2-leaflet';

@Component({
    components: {
        LPopup,
        LCircleMarker,
    },
    props: {
        cenote: CenoteDTO,
        single: Boolean,
    },
})
export default class MapMarker extends Vue {
    mounted(): void {
        this.$nextTick(() => {
            if (this.$props.single)
                (this.$refs.marker as LCircleMarker).mapObject.openPopup();
        });
    }
}
