









































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LoginCard extends Vue {
    email = '';
    emailRules = [
        (v: string): string | true => !!v || 'E-mail is required',
        (v: string): string | true =>
            /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ];

    password = '';
    passwordRules = [
        (v: string): string | true => !!v || 'Password is required',
        (v: string): string | true =>
            (v && v.length >= 8) || 'Password must have at least 8 characters',
    ];
    showPassword = false;

    submit(): void {
        this.$emit('onSubmit', this.email, this.password);
    }

    validate(): boolean {
        let res = this.emailRules
            .map((rule) => rule(this.email))
            .every((res) => res === true);
        res &&= this.passwordRules
            .map((rule) => rule(this.password))
            .every((res) => res === true);
        return res;
    }
}
