













import { Component, Vue, Watch } from 'vue-property-decorator';
@Component
export default class ErrorMessage extends Vue {
    dialog: boolean = this.$store.getters.getError;
    errorMessage: string = this.$store.getters.getErrorMessage;
    created(): void {
        this.dialog = this.$store.getters.getError;
        this.errorMessage = this.$store.getters.getErrorMessage;
        this.$store.watch(
            (state, getters) => getters.getError,
            () => {
                this.dialog = this.$store.getters.getError;
                this.errorMessage = this.$store.getters.getErrorMessage;
            },
        );
    }
    @Watch('dialog')
    closeError(): void {
        if (!this.dialog) {
            this.$store.dispatch('clearError');
        }
    }
}
