




























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

interface NavIcon {
    icon: string;
    dataCy: string;
    text: string;
    to?: string;
    action?: () => void;
    condition?: () => boolean;
    children?: Omit<NavIcon, 'children'>[];
}

@Component({
    computed: {
        ...mapGetters(['isLoggedIn', 'isAdmin']),
    },
})
export default class NavBar extends Vue {
    name = 'NavBar';
    isLoggedIn!: boolean;
    isAdmin!: boolean;
    drawer = false;

    private _links: NavIcon[] = [];

    created(): void {
        this._links = [
            {
                icon: 'mdi-shield-account',
                dataCy: 'admin',
                text: 'ADMIN',
                to: '/admin',
                condition: () => this.isAdmin,
                children: [
                    {
                        icon: 'mdi-monitor-dashboard',
                        dataCy: 'dashboard',
                        text: 'DASHBOARD',
                        to: '/admin',
                    },
                    {
                        icon: 'mdi-monitor-edit',
                        dataCy: 'variables',
                        text: 'VARIABLES',
                        to: '/admin/variables',
                    },
                    {
                        icon: 'mdi-monitor-edit',
                        dataCy: 'users',
                        text: 'USERS',
                        to: '/admin/users',
                    },
                    {
                        icon: 'mdi-monitor-edit',
                        dataCy: 'cenotes',
                        text: 'CENOTES',
                        to: '/admin/cenotes',
                    },
                    {
                        icon: 'mdi-monitor-edit',
                        dataCy: 'species',
                        text: 'SPECIES',
                        to: '/admin/species',
                    },
                    {
                        icon: 'mdi-monitor-edit',
                        dataCy: 'references',
                        text: 'REFERENCES',
                        to: '/admin/references',
                    },
                ],
            },
            {
                icon: 'mdi-map',
                dataCy: 'map',
                text: 'MAP',
                to: '/map',
            },
            {
                icon: 'mdi-source-branch',
                dataCy: 'oai-pmh',
                text: 'OAI-PMH',
                to: '/oai-pmh',
                children: [
                    {
                        icon: 'mdi-account-box-outline',
                        dataCy: 'identify',
                        text: 'IDENTIFY',
                        to: '/oai-pmh/identify',
                    },
                    {
                        icon: 'mdi-magnify',
                        text: 'GET RECORD',
                        dataCy: 'get-record',
                        to: '/oai-pmh/get-record',
                    },
                    {
                        icon: 'mdi-view-list',
                        text: 'LIST RECORDS',
                        dataCy: 'list-records',
                        to: '/oai-pmh/list-records',
                    },
                ],
            },
            {
                icon: 'mdi-account',
                dataCy: 'login',
                text: 'LOGIN / SIGN UP',
                to: '/login',
                condition: () => !this.isLoggedIn,
            },
            {
                icon: 'mdi-account-off',
                dataCy: 'logout',
                text: 'LOGOUT',
                action: this.logout,
                condition: () => this.isLoggedIn,
            },
        ];
    }

    get activeLinks(): NavIcon[] {
        let links = this._links.filter(
            (link) => link.condition == undefined || link.condition(),
        );
        links.map((link) => {
            if (!link.action)
                link.action = () => {
                    return;
                };
            if (link.children)
                link.children = link.children.filter(
                    (child) =>
                        child.condition == undefined || child.condition(),
                );
            return link;
        });
        return links;
    }

    async logout(): Promise<void> {
        await this.$store.dispatch('logout');
        await this.$router.push({ name: 'Home' }).catch(() => {
            // Ignore errors
            return;
        });
    }
}
