































































































import CenoteDetails from '@/components/cenote/CenoteDetails.vue';
import MapMarker from '@/components/map/MapMarker.vue';
import CenoteDTO from '@/models/CenoteDTO';
import RemoteServices from '@/services/RemoteServices';
import L from 'leaflet';
import { Component, Vue } from 'vue-property-decorator';
import { LCircleMarker, LMap, LTileLayer } from 'vue2-leaflet';

@Component({
    components: {
        LMap,
        LTileLayer,
        LCircleMarker,
        MapMarker,
        CenoteDetails,
    },
})
export default class Cenote extends Vue {
    marker: L.CircleMarker | null = null;
    cenote: CenoteDTO | null = null;
    photos: Array<string> | null = null;
    maps: Array<string> | null = null;

    zoom = 12;
    url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    attribution =
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors';
    mapOptions = {
        zoomSnap: 0.5,
    };

    async created(): Promise<void> {
        await this.$store.dispatch('loading');
        try {
            this.photos = await RemoteServices.getPhotosCenotes(
                this.$route.params.key,
            );
            this.maps = await RemoteServices.getMapsCenotes(
                this.$route.params.key,
            );
            this.cenote = await RemoteServices.getCenote(
                this.$route.params.key,
            );
        } catch (error) {
            await this.$store.dispatch('error', error);
        }
        await this.$store.dispatch('clearLoading');
    }
}
